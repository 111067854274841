import React from 'react'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { store } from '../state/createStore'
import icon from '../images/icon.png'
import NavbarPrincipal from './navbar'
import Footer from './footer'
import "../styles/styles.scss"
import fondo from '../images/salem.gif'
import Scroll from './Scroll'

const Layout = ({children}) => {
    return (
        <Provider store={store} >
                <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
                <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>

            <Helmet>
                <meta charset="utf-8" />
                <link rel="icon" href={icon} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="theme-color" content="#fffff" />
                <meta name="description" content="Sitio oficial colegio cielos de valle" />
                <title>Colegio Cielos del Valle</title>
            </Helmet>
            <main style={{
                backgroundImage:`url(${fondo})`, 
                backgroundRepeat:'repeat', 
                backgroundSize:'180px', 
                backgroundPosition:'fixed',
                minHeight:`${100}vh`
            }}>
                <Scroll mostrarBajada={20}/>
                <NavbarPrincipal />
                {children}
            </main>
            <Footer />
        </Provider>
    )
}

export default Layout
