import { types } from "../types/types";
// admin
export const openHistoria = () => ({ type: types.openHistoria });
export const openMision = () => ({ type: types.openMision });
export const openVision = () => ({ type: types.openVision });
export const openMensajeSostenedora = () => ({ type: types.openMensajeSostenedora });
export const openProyectoEducativo = () => ({ type: types.openProyectoEducativo });
export const openConvivenciaEscolar = () => ({ type: types.openConvivenciaEscolar });
export const openReglamentos = () => ({ type: types.openReglamentos });
export const openPerfilEstudiante = () => ({ type: types.openPerfilEstudiante })
export const openModalidadEstudios = () => ({ type: types.openModalidadEstudios })
export const openGaleriaMultimedia = () => ({ type: types.openGaleriaMultimedia })
export const openGaleria = () => ({ type: types.openGaleria })