import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import imageScroll from '../images/button_up_back.png';
// const imageScroll=require.context('../../src/images', true);



const Scroll=({mostrarBajada})=>{
    const [mostrar, setMostrar]=useState(mostrarBajada?false:true);


    const handleScroll=()=>{
        if(window.pageYOffset>mostrarBajada){
            if(!mostrar) setMostrar(true);
        }else{
            if(mostrar) setMostrar(false);
        }
    }

    useEffect(()=>{
        if(mostrarBajada){
            window.addEventListener('scroll', handleScroll);
            return ()=> window.removeEventListener('scroll', handleScroll)
        }
    })

    const handleClick=()=>{
        window[`scrollTo`]({top:0, behavior:`smooth`})
    }
    return(
        <div>
            {
                mostrar &&
                <Button type="button" onClick={handleClick} className="btn btn-primary btn-Block toTop">
                    <Image src={imageScroll} style={{width:'30px'}} roundedCircle />
                </Button>
            }
        </div>
    )
}
export default Scroll;