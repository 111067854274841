import { types } from "../types/types";

const initialState = {
    galerias: [],
    galeriaActiva: {},
};

export const galeriaMultimediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.activarGaleriaMultimedia:
        return {
            ...state,
            galeriaActiva: action.payload
        }
    case types.obtenerGaleriasMultimedia:
        return {
            ...state,
            galerias : action.payload
        }
    case types.guardarGaleriaMultimedia:
        return {
            ...state,
            galeriaActiva: state.galerias.find(galeria=>galeria.id===action.payload)
        }
    default:
      return state;
  }
};

