import { types } from "../types/types";

const initialState = {
    noticias: [],
    noticiaActiva: {},
};

export const noticiaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.activarNoticia:
        return {
            ...state,
            noticiaActiva: action.payload
        }
    case types.obtenerNoticias:
        return {
            ...state,
            noticias : action.payload
        }
    default:
      return state;
  }
};