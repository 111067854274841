import { types } from "../types/types";

const initialState = {
  historia:true,
  mision:false,
  vision:false,
  mensajeSostenedora:false,
  proyectoEducativo:false,
  convivenciaEscolar:false,
  reglamentos:false,
  perfilEstudiante:false,
  modalidadEstudios:false,
  galeriaMultimedia:false,
  cardGaleriaImagen:false,
};

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.openHistoria:
      return {
        ...state,
        historia:true,
        mision:false,
        vision:false,
        mensajeSostenedora:false,
        proyectoEducativo:false,
        convivenciaEscolar:false,
        reglamentos:false,
        perfilEstudiante:false,
        modalidadEstudios:false,
        galeriaMultimedia:false,
        cardGaleriaImagen:false,
      }
    case types.openMision:
      return {
        ...state,
        historia:false,
        mision:true,
        vision:false,
        mensajeSostenedora:false,
        proyectoEducativo:false,
        convivenciaEscolar:false,
        reglamentos:false,
        perfilEstudiante:false,
        modalidadEstudios:false,
        galeriaMultimedia:false,
        cardGaleriaImagen:false,
      }
    case types.openVision:
      return {
        ...state,
        historia:false,
        mision:false,
        vision:true,
        mensajeSostenedora:false,
        proyectoEducativo:false,
        convivenciaEscolar:false,
        reglamentos:false,
        perfilEstudiante:false,
        modalidadEstudios:false,
        galeriaMultimedia:false,
        cardGaleriaImagen:false,
      }
    case types.openMensajeSostenedora:
      return {
        ...state,
        historia:false,
        mision:false,
        vision:false,
        mensajeSostenedora:true,
        proyectoEducativo:false,
        convivenciaEscolar:false,
        reglamentos:false,
        perfilEstudiante:false,
        modalidadEstudios:false,
        galeriaMultimedia:false,
        cardGaleriaImagen:false,
      }
    case types.openProyectoEducativo:
      return {
        ...state,
        historia:false,
        mision:false,
        vision:false,
        mensajeSostenedora:false,
        proyectoEducativo:true,
        convivenciaEscolar:false,
        reglamentos:false,
        perfilEstudiante:false,
        modalidadEstudios:false,
        galeriaMultimedia:false,
        cardGaleriaImagen:false,
      }
    case types.openConvivenciaEscolar:
      return {
        ...state,
        historia:false,
        mision:false,
        vision:false,
        mensajeSostenedora:false,
        proyectoEducativo:false,
        convivenciaEscolar:true,
        reglamentos:false,
        perfilEstudiante:false,
        modalidadEstudios:false,
        galeriaMultimedia:false,
        cardGaleriaImagen:false,
      }
    case types.openReglamentos:
      return {
        ...state,
        historia:false,
        mision:false,
        vision:false,
        mensajeSostenedora:false,
        proyectoEducativo:false,
        convivenciaEscolar:false,
        reglamentos:true,
        perfilEstudiante:false,
        modalidadEstudios:false,
        galeriaMultimedia:false,
        cardGaleriaImagen:false,
      }
    case types.openPerfilEstudiante:
      return {
        ...state,
        historia:false,
        mision:false,
        vision:false,
        mensajeSostenedora:false,
        proyectoEducativo:false,
        convivenciaEscolar:false,
        reglamentos:false,
        perfilEstudiante:true,
        modalidadEstudios:false,
        cardGaleriaImagen:false,
        galeriaMultimedia:false,
      }
    case types.openModalidadEstudios:
      return {
        ...state,
        historia:false,
        mision:false,
        vision:false,
        mensajeSostenedora:false,
        proyectoEducativo:false,
        convivenciaEscolar:false,
        reglamentos:false,
        perfilEstudiante:false,
        modalidadEstudios:true,
        cardGaleriaImagen:false,
        galeriaMultimedia:false,
      }
    case types.openGaleriaMultimedia:
      return{
        ...state,
        historia:false,
        mision:false,
        vision:false,
        mensajeSostenedora:false,
        proyectoEducativo:false,
        convivenciaEscolar:false,
        reglamentos:false,
        perfilEstudiante:false,
        modalidadEstudios:false,
        cardGaleriaImagen:false,
        galeriaMultimedia:true,
      }
      case types.openGaleria:
      return{
        ...state,
        historia:false,
        mision:false,
        vision:false,
        mensajeSostenedora:false,
        proyectoEducativo:false,
        convivenciaEscolar:false,
        reglamentos:false,
        perfilEstudiante:false,
        modalidadEstudios:false,
        galeriaMultimedia:false,
        cardGaleriaImagen:true
      }
    default:
      return state;
  }
};