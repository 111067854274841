import React, {useState} from 'react'
import { useDispatch } from 'react-redux';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import * as gatsby from 'gatsby'

import { 
  openConvivenciaEscolar, 
  openGaleriaMultimedia, 
  openHistoria, 
  openModalidadEstudios, 
  openPerfilEstudiante, 
  openProyectoEducativo, 
  openReglamentos 
} from '../action/admin';

const NavbarPrincipal = () => {
  const dispatch = useDispatch();
  const [ishovernue, setIsHoveredNuestro] = useState(false);
  const [isclickednue, setIsClickedNuestro] = useState(false);

  const [isHoveredCom, setIsHoveredComunidad] = useState(false);
  const [isClickedCom, setIsClickedComunidad] = useState(false);

  const { logo } = gatsby.useStaticQuery(gatsby.graphql`
    query {
      logo: file(relativePath: {eq: "logo-dark-navbar.png"}){
        publicURL
      }
    }
  `)

  return (
    <Navbar bg="dark" variant="dark" expand="lg" >
      <Navbar.Brand onClick={() => gatsby.navigate("/")}>
          <img src={logo.publicURL} alt="Colegio Colegio Cielos del Valle" style={{height:'35px'}}/>
      </Navbar.Brand>
      <Navbar.Toggle  aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
          <Nav.Link onClick={() => gatsby.navigate("/")}>
            Inicio
          </Nav.Link>
          <NavDropdown title="Nuestro Colegio" id="basic-nav-dropdown"
          onTouchStart={() => setIsHoveredNuestro(true)}
          onTouchEnd={() => setIsHoveredNuestro(false)}
          onMouseEnter={() => setIsHoveredNuestro(true)}
          onMouseLeave={() => setIsHoveredNuestro(false)}
          onToggle={() => setIsClickedNuestro(!isclickednue)}
          show={isclickednue || ishovernue}>
                      <NavDropdown.Item onClick={() => {
                                    gatsby.navigate("/colegio")
                                    dispatch(openHistoria())
                                  }}>
                                      Colegio Cielos del Valle
                                  </NavDropdown.Item>
                                  <NavDropdown.Item onClick={() => {
                                    gatsby.navigate("/colegio")
                                    dispatch(openGaleriaMultimedia())
                                  }}>
                                    Galeria Multimedia
                                  </NavDropdown.Item>
                                  <NavDropdown.Item onClick={()=> {
                                    gatsby.navigate("/colegio")
                                    dispatch(openConvivenciaEscolar())
                                  }}>
                                    Convivencia Escolar
                                  </NavDropdown.Item>
                                  <NavDropdown.Item onClick={()=> {
                                    gatsby.navigate("/noticias")
                                    dispatch(openConvivenciaEscolar())
                                  }}>
                                    Noticias
                                  </NavDropdown.Item>
                                  <NavDropdown.Item onClick={()=> {
                                    gatsby.navigate("/colegio")
                                    dispatch(openReglamentos())
                                  }}>
                                    Reglamentos
                                  </NavDropdown.Item>
                                  <NavDropdown.Item  onClick={()=> {
                                    gatsby.navigate("/colegio")
                                    dispatch(openProyectoEducativo())
                                  }}>
                                    Proyecto Educativo
                                  </NavDropdown.Item>
                                  <NavDropdown.Item onClick={()=> {
                                    gatsby.navigate("/colegio")
                                    dispatch(openPerfilEstudiante())
                                  }}>
                                      Perfil del estudiante
                                  </NavDropdown.Item>
                                  <NavDropdown.Item  onClick={()=> {
                                    gatsby.navigate("/colegio")
                                    dispatch(openModalidadEstudios())
                                  }}>
                                    Modalidad de estudios
                                  </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Comunidad Educativa" id="basic-nav-dropdown" 
          onMouseEnter={() => setIsHoveredComunidad(true)}
          onMouseLeave={() => setIsHoveredComunidad(false)}
          onTouchStart={() => setIsHoveredComunidad(true)}
          onTouchEnd={() => setIsHoveredComunidad(false)}
          onToggle={() => setIsClickedComunidad(!isClickedCom)}
          show={isClickedCom || isHoveredCom}>
            <NavDropdown.Item onClick={ () => gatsby.navigate("/comunidad/centro-alumnos")}>Centro de alumnos</NavDropdown.Item>
            <NavDropdown.Item onClick={ () => gatsby.navigate("/comunidad/horario-clases")}>Horario de clases</NavDropdown.Item>
            <NavDropdown.Item onClick={ () => gatsby.navigate("/comunidad/centro-padres")}>Centro de padres</NavDropdown.Item>
            <NavDropdown.Item onClick={ () => gatsby.navigate("/comunidad/atencion-apoderado")}>Atencion apoderados</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link onClick={ () => gatsby.navigate("/admision")}>Admision</Nav.Link>
          <Nav.Link onClick={ () => gatsby.navigate("/retorno-seguro")}>Retorno seguro</Nav.Link>
          <Nav.Link onClick={ () => gatsby.navigate("/contacto")}>Contacto</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar> 
  )
}

export default NavbarPrincipal;