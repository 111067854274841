export const types = {
    openHistoria: '[Historia] Open',
    openMision: '[Mision] Open',
    openVision: '[Vision] Open',
    openMensajeSostenedora: '[MensajeSostenedora] Open',
    openProyectoEducativo: '[ProyectoEducativo] Open',
    openConvivenciaEscolar: '[ConvivenciaEscolar] Open',
    openReglamentos: '[Reglamentos] Open',
    openPerfilEstudiante: '[PerfilEstudiante] Open',
    openModalidadEstudios: '[ModalidadEstudios] Open',
    
    openGaleriaMultimedia:'[galeriaMultimedia] Open',
    openGaleria:'[cardGaleriaImagen] Open',
    // activaGaleriaMultimedia:'[galeriaMultimedia] Activa',
    obtenerGaleriasMultimedia:'[galeriaMultimedia] Obtener',
    guardarGaleriaMultimedia:'[galeriaMultimedia] Save',
    
    obtenerNoticias: '[Noticias] Obtener',
    activarNoticia: '[Noticia] Activa',
};